import { arrayRange } from './helpers';
import { IAlphabetTransliterationsType } from '@/interfaces/alphabetTransliterations.interface';

const BOOKS_FOR_PAGE = 20;
const AUTHORS_FOR_PAGE = 100;
const SERIES_FOR_PAGE = 17;
const TOP_FIFTY = 50;
const REVIEWS_FOR_SLIDER = 10;
const PRERENDER_PAGES = 1;
const TAGS_FOR_PAGE = 40;
const GENRES_FOR_PAGE = 40;
const THEMES_FOR_FORUM_PAGE = 20;
const MESSAGES_FOR_FORUM_PAGE = 50;

const RUSSIAN_ALPHABET = ['А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ё', 'Ж', 'З', 'И', 'Й', 'К', 'Л', 'М', 'Н', 'О', 'П', 'Р', 'С', 'Т', 'У', 'Ф', 'Х', 'Ц', 'Ч', 'Ш', 'Щ', 'Э', 'Ю', 'Я'];
const ENGLISH_ALPHABET = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];

const ALPHABET_TRANSLITERATIONS: IAlphabetTransliterationsType = {
  ru_a: 'А',
  ru_b: 'Б',
  ru_v: 'В',
  ru_g: 'Г',
  ru_d: 'Д',
  ru_e: 'Е',
  ru_io: 'Ё',
  ru_zh: 'Ж',
  ru_z: 'З',
  ru_i: 'И',
  ru_y: 'Й',
  ru_k: 'К',
  ru_l: 'Л',
  ru_m: 'М',
  ru_n: 'Н',
  ru_o: 'О',
  ru_p: 'П',
  ru_r: 'Р',
  ru_s: 'С',
  ru_t: 'Т',
  ru_u: 'У',
  ru_f: 'Ф',
  ru_h: 'Х',
  ru_tz: 'Ц',
  ru_ch: 'Ч',
  ru_sh: 'Ш',
  ru_sz: 'Щ',
  ru_bh: 'Ъ',
  ru_bi: 'Ы',
  ru_bs: 'Ь',
  ru_ie: 'Э',
  ru_yu: 'Ю',
  ru_ia: 'Я',
  en_a: 'A',
  en_b: 'B',
  en_c: 'C',
  en_d: 'D',
  en_e: 'E',
  en_f: 'F',
  en_g: 'G',
  en_h: 'H',
  en_i: 'I',
  en_j: 'J',
  en_k: 'K',
  en_l: 'L',
  en_m: 'M',
  en_n: 'N',
  en_o: 'O',
  en_p: 'P',
  en_q: 'Q',
  en_r: 'R',
  en_s: 'S',
  en_t: 'T',
  en_u: 'U',
  en_v: 'V',
  en_w: 'W',
  en_x: 'X',
  en_y: 'Y',
  en_z: 'Z',
};

const DAYS = arrayRange(1, 31, 1);
const MONTHS = [
  { name: 'Январь', value: '01' },
  { name: 'Февраль', value: '02' },
  { name: 'Март', value: '03' },
  { name: 'Апрель', value: '04' },
  { name: 'Май', value: '05' },
  { name: 'Июнь', value: '06' },
  { name: 'Июль', value: '07' },
  { name: 'Август', value: '08' },
  { name: 'Сентябрь', value: '09' },
  { name: 'Октябрь', value: '10' },
  { name: 'Ноябрь', value: '11' },
  { name: 'Декабрь', value: '12' },
];

const MONTH_NAMES = ['Январь', 'Февраль', 'Март', 'Апрель', 'Май', 'Июнь', 'Июль', 'Август', 'Сентябрь', 'Октябрь', 'Ноябрь', 'Декабрь'];
const MONTH_NAMES_ROD = ['Января', 'Февраля', 'Марта', 'Апреля', 'Мая', 'Июня', 'Июля', 'Августа', 'Сентября', 'Октября', 'Ноября', 'Декабря'];

const YEARS = arrayRange(1923, 2018, 1);
const GENDER = [
  { name: 'Мужской', value: 'male' },
  { name: 'Женский', value: 'female' },
];
const FILTER_LANGS = [
  { lang: 'foreign', name: 'Зарубежные' },
  { lang: 'russian', name: 'Русские' },
  { lang: 'all', name: 'Все' },
];

const ABSOLUTE_URL = 'https://litportal.ru';
// Переменная для разработки, абсолютным url - для картинок/фрагментов текста/аудио
// const DEV_ABSOLUTE_URL = ABSOLUTE_URL;
const DEV_ABSOLUTE_URL = '';

const config = {
  headers: {
    Accept: '/',
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE, OPTIONS',
    'Access-Control-Allow-Headers': 'Authorization',
  },
};

const API_URL = `${process.env.APP_URL}`;

const MENU_API = {
  genres: `${API_URL}satellite/genres/popular?count=30`,
};

const REVALIDATE_TIME = 300;

// type menuLinkObject = {
//   name: string;
//   link: string;
// };

const MENU_LINKS = [
  { name: 'Жанры', link: '/genres/' },
  { name: 'Популярные', link: '/populyarnye/' },
  { name: 'Аудиокниги', link: '/audioknigi/' },
  { name: 'Новинки', link: '/new/' },
  { name: 'Серии и саги', link: '/series/' },
  { name: 'Тэги', link: '/tag/' },
  { name: 'Все авторы', link: '/avtory/' },
  { name: 'Сонник', link: '/sonnik/' },
  { name: 'Форум', link: '/forum/' },
  { name: 'Контакты', link: '/kontakt/' },
];

const CATEGORY_LINK = {
  books: 'Книги',
  audio_books: 'Аудиокниги',
  authors: 'Авторы',
  genres: 'Жанры',
  series: 'Серии',
  tags: 'Тэги',
  drafts: 'Черновики',
  podcasts: 'Подкасты',
  free_books: 'Бесплатные книги',
};

const RECAPTCHA_SITE_KEY = `${process.env.RECAPTCHA_SITE_KEY}`;

export {
  BOOKS_FOR_PAGE,
  RUSSIAN_ALPHABET,
  ENGLISH_ALPHABET,
  ALPHABET_TRANSLITERATIONS,
  DAYS,
  MONTHS,
  YEARS,
  GENDER,
  MONTH_NAMES,
  MONTH_NAMES_ROD,
  API_URL,
  PRERENDER_PAGES,
  TOP_FIFTY,
  AUTHORS_FOR_PAGE,
  SERIES_FOR_PAGE,
  FILTER_LANGS,
  ABSOLUTE_URL,
  DEV_ABSOLUTE_URL,
  REVIEWS_FOR_SLIDER,
  MENU_API,
  REVALIDATE_TIME,
  TAGS_FOR_PAGE,
  GENRES_FOR_PAGE,
  MENU_LINKS,
  MESSAGES_FOR_FORUM_PAGE,
  THEMES_FOR_FORUM_PAGE,
  CATEGORY_LINK,
  RECAPTCHA_SITE_KEY,
  config,
};
